import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

// markup
const BadRequest = () => {
  return (
    <Layout classNames="error404">
      <Seo title="403 Error" seoTitle="403 Error" seoDescription="Forbidden" />
      <section className="banner-wrap">
        <div id="banner" className="banner">
          <div className="banner-bg-image col-xs-16 ph0 small " style={{backgroundImage: 'url("https://strapi.hc1.com/uploads/resources_hero_f7e0fa569f.jpg?3038962")', opacity: 1}}>
          </div>
          <div className="banner-overlay" />
          <div className="banner-inside no-overlay">
            <div className="col-xs-16 banner-text text-center">
              <h1 className>403 Forbidden</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content-wrap">
        <div className="container">
          <div className="row">
            <div className="col" style={{marginBottom: '2rem'}}>
              <h1>403. Forbidden.</h1>
              <h2>Forbidden</h2>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BadRequest
